import {
  Cushion,
  FlexContainer,
  Image,
  Typography,
  withTheme,
  RelativeContainer,
} from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import LockIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Lock';
import ClubLink from '../club-link';
import ShareButton from '../social/share-button';
import PublishedDate from '../published-date';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import clubUrl from '../../lib/routing/club-url';
import useBreakpoint from '../../lib/use-breakpoint';
import CategoryTag from './category';
import InfoLine from './info-line';
import IconWithTheme from '../icon-with-theme';

const HeroContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  max-width: 1366px;
  margin: 0 auto;
  cursor: pointer;
  ${(props) => `
      background-color: ${props.bgColor};
      color: ${props.textColor};
    `};
  ${(props) => {
    const { breakpoint } = props;
    if (!breakpoint) {
      return null;
    }

    return `
    @media (min-width: ${breakpoint}px) {
      grid-template-rows: 1fr;
      grid-template-columns: 5fr 4fr;
      border-radius: 10px;
      overflow: hidden;
    }
    `;
  }}
`;

const HeroBody = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeroTitle = styled.div`
  font-family: anton;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${(props) => (props.isMobile ? '2rem' : '2.5rem')};
  line-height: ${(props) => (props.isMobile ? '2.5rem' : '3rem')};
  letter-spacing: 0.1rem;
  // Use resolution media query on higher pixel ratio devices for letter spacing
  @media (min-resolution: 3x) {
    letter-spacing: normal;
  }
`;

const Hero = ({
  club,
  category,
  date,
  imageSrc,
  imageProps,
  title,
  linkProps,
  stacked,
  tagline,
  author,
  uiTheme,
  isPrivate,
}) => {
  const bgColor = 'rgba(50, 50, 50, 0.5)';
  const textColor = '#fff';
  const { lap: lapBreakpoint, palm: palmBreakpoint } = uiTheme.breakpoints;
  const shareUrl = clubUrl(club, linkProps.route, linkProps.params);
  const isMobile = useBreakpoint();

  const imageStyle = {
    display: 'block',
    width: '100%',
    height: 'auto',
    aspectRatio: '16 / 9',
    ...imageProps.style,
  };

  if (isPrivate) {
    imageStyle.filter = 'blur(10px)';
    imageStyle.opacity = 0.9;
  }

  const getBreakpoint = () => {
    if (stacked === 'desk') {
      return lapBreakpoint - 1;
    }

    if (stacked === 'tab-and-up') {
      return palmBreakpoint - 1;
    }

    return null;
  };

  const privateLockIcon = (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0.9,
      }}
    >
      <IconWithTheme>
        <LockIcon color="white" size={100} />
      </IconWithTheme>
    </div>
  );

  return (
    <ClubLink {...linkProps}>
      <HeroContainer
        bgColor={bgColor}
        textColor={textColor}
        breakpoint={getBreakpoint()}
        className="cardHighlightSlow"
      >
        <RelativeContainer style={{ overflow: 'hidden' }}>
          <Image
            src={imageSrc}
            alt={title}
            shimmerOnLoad
            loading="eager"
            heroImage
            width={980}
            height={550}
            {...imageProps}
            style={imageStyle}
          />
          {isPrivate && <>{privateLockIcon}</>}
        </RelativeContainer>
        <HeroBody>
          <div>
            {!isMobile && (
              <FlexContainer>
                <CategoryTag category={category} />
              </FlexContainer>
            )}
            <Cushion bottom="small" top={isMobile ? 0 : 'medium'}>
              <HeroTitle isMobile={isMobile}>{title}</HeroTitle>
            </Cushion>
            {tagline && (
              <Cushion vertical="small" component="div">
                <Typography preset="description" size={16}>
                  {tagline}
                </Typography>
              </Cushion>
            )}
          </div>
          <FlexContainer alignItems="center" justifyContent="space-between">
            <FlexContainer alignItems="center">
              {isMobile && (
                <Cushion right="xsmall" component="div">
                  <CategoryTag category={category} />
                </Cushion>
              )}
              <FlexContainer alignItems="center">
                <InfoLine size={15}>
                  <PublishedDate date={date} />
                </InfoLine>
                {author && !isMobile && (
                  <Typography size={15} preset="description">
                    &nbsp; - &nbsp;
                    {author}
                  </Typography>
                )}
              </FlexContainer>
            </FlexContainer>
            {shareUrl && (
              <div
                role="button"
                className="heroShare"
                aria-label="Share this artcile"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
                tabIndex={0}
              >
                <ShareButton
                  color={textColor}
                  url={shareUrl}
                  title={title}
                  iconSize={24}
                  description={tagline}
                  imageUrl={imageSrc}
                />
              </div>
            )}
          </FlexContainer>
        </HeroBody>
      </HeroContainer>
    </ClubLink>
  );
};

Hero.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  imageProps: PropTypes.shape(),
  linkProps: PropTypes.shape({
    route: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
  }),
  stacked: PropTypes.oneOf(['always', 'tab-and-up', 'desk', 'never']),
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  author: PropTypes.string,
  uiTheme: PropTypes.shape().isRequired,
  isPrivate: PropTypes.bool,
};

Hero.defaultProps = {
  category: null,
  date: null,
  linkProps: null,
  stacked: 'always',
  tagline: null,
  author: null,
  imageProps: {},
  isPrivate: false,
};

export default withTheme(Hero);

import React from 'react';
import PropTypes from 'prop-types';
import { AbsoluteContainer, Grid, GridItem, Rectangle, Typography } from '@pitchero/react-ui';
import PublishedDate from '../published-date';

const MediaOverlay = ({ children, isMobile, publishedDate }) => (
  <AbsoluteContainer
    bottom={0}
    left={0}
    right={0}
    style={{
      height: isMobile ? '36px' : '60px',
      width: '100%',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9) 100%)',
      padding: '10px 5px',
    }}
  >
    <Grid columns={`${isMobile ? '36px' : '60px'} auto`} style={{ width: '100%', height: '100%' }}>
      <Rectangle
        radius="100%"
        fill="primary"
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        component="div"
      >
        {children}
      </Rectangle>
      <GridItem justifySelf="end" alignSelf="end" style={{ paddingRight: '10px' }}>
        <Typography size={isMobile ? 12 : 16} transform="capitalize" color="#fff">
          <PublishedDate date={publishedDate} />
        </Typography>
      </GridItem>
    </Grid>
  </AbsoluteContainer>
);

MediaOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  publishedDate: PropTypes.string.isRequired,
};

export default MediaOverlay;

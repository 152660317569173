import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@pitchero/react-ui';
import styled from 'styled-components';

const MediaContainer = styled.div`
  box-shadow: ${(props) => (props.shadow ? '0 5px 10px 0 rgba(0, 0, 0, 0.05)' : 'none')};
  height: 100%;
  display: ${(props) => (props.stacked === 'always' ? 'block' : 'grid')};
  grid-template-columns: 2fr 3fr;
  grid-gap: 0;
  align-items: start;
  ${(props) => {
    const { breakpoint } = props;
    if (!breakpoint) {
      return null;
    }

    return `
    @media (min-width: ${breakpoint}px) {
      display: block;
    }
    `;
  }}
`;

const MediaContainerV2 = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.stacked === 'always' ? '1fr' : '1fr 1fr')};
  ${(props) => {
    const { breakpoint } = props;
    if (!breakpoint) {
      return null;
    }

    return `
    @media (min-width: ${breakpoint}px) {
      grid-template-columns: 1fr;
      grid-template-rows: max-content auto;
    }
    `;
  }}
`;

const MediaImg = styled.div`
  padding: ${(props) => (props.stacked === 'always' ? '0' : '24px 0 24px 24px')};
  ${(props) => {
    const { breakpoint } = props;
    if (!breakpoint) {
      return null;
    }

    return `
    @media (min-width: ${breakpoint}px) {
      padding: 0;
    }
    `;
  }}
`;

const MediaImgV2 = styled.div`
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  ${(props) => {
    const { breakpoint } = props;
    if (!breakpoint) {
      return null;
    }

    return `
    @media (min-width: ${breakpoint}px) {
      border-radius: 0px;
    }
    `;
  }}
`;

const MediaBody = styled.div`
  padding: 24px;
`;
const MediaBodyV2 = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 0 10px;
  display: flex;
  ${(props) => {
    const { breakpoint } = props;
    if (!breakpoint) {
      return null;
    }

    return `
    @media (min-width: ${breakpoint}px) {
      background-color: white;
      padding: 15px;
    }
    `;
  }}
`;

const MediaItem = ({
  stacked,
  renderImage,
  renderBody,
  style: otherStyles,
  uiTheme,
  isV2,
  ...otherProps
}) => {
  const {
    lap: lapBreakpoint,
    palm: palmBreakpoint,
    fullHeader: fullHeaderBreakpoint,
  } = uiTheme.breakpoints;

  const getbreakpoint = () => {
    if (stacked === 'desk') {
      return lapBreakpoint - 1;
    }

    if (stacked === 'tab-and-up') {
      return palmBreakpoint - 1;
    }

    if (stacked === 'fullHeader') {
      return fullHeaderBreakpoint - 1;
    }

    return null;
  };
  const breakpoint = getbreakpoint();

  if (isV2) {
    return (
      <MediaContainerV2
        stacked={stacked}
        breakpoint={breakpoint}
        style={otherStyles}
        {...otherProps}
      >
        <MediaImgV2 breakpoint={breakpoint}>{renderImage()}</MediaImgV2>
        <MediaBodyV2 breakpoint={breakpoint}>{renderBody()}</MediaBodyV2>
      </MediaContainerV2>
    );
  }

  return (
    <MediaContainer stacked={stacked} breakpoint={breakpoint} style={otherStyles} {...otherProps}>
      <MediaImg stacked={stacked} breakpoint={breakpoint}>
        {renderImage()}
      </MediaImg>
      <MediaBody breakpoint={breakpoint}>{renderBody()}</MediaBody>
    </MediaContainer>
  );
};

MediaItem.defaultProps = {
  shadow: false,
  stacked: 'always',
  style: {},
  isV2: false,
};

MediaItem.propTypes = {
  shadow: PropTypes.bool,
  stacked: PropTypes.oneOf(['always', 'desk', 'fullHeader', 'tab-and-up', 'never']),
  renderImage: PropTypes.func.isRequired,
  renderBody: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  uiTheme: PropTypes.shape().isRequired,
  isV2: PropTypes.bool,
};
export default withTheme(MediaItem);

import {
  Cushion,
  FlexContainer,
  Image,
  Rectangle,
  RelativeContainer,
  Show,
  Space,
  Typography,
} from '@pitchero/react-ui';
import LockIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Lock';
import PropTypes from 'prop-types';
import React from 'react';
import ClubLink from '../club-link';
import IconWithTheme from '../icon-with-theme';
import MediaItem from '../media-item';
import PublishedDate from '../published-date';

import CategoryTag from './category';
import InfoLine from './info-line';

const Card = ({
  date,
  linkProps,
  imageSrc,
  isPrivate,
  title,
  category,
  stacked,
  renderAfterImage,
  shadow,
  style,
  imageProps,
  imagePreset,
  titleColor,
  dateColor,
  tagline,
  totalImages,
  duration,
  isV2,
}) => {
  const wrapWithOnClickLink = (element) => {
    if (linkProps) {
      return <ClubLink {...linkProps}>{element}</ClubLink>;
    }

    return element;
  };

  const wrapWithLink = (element) => {
    if (linkProps) {
      return (
        <ClubLink {...linkProps}>
          <a style={{ display: 'flex' }} aria-label={title}>
            {element}
          </a>
        </ClubLink>
      );
    }

    return element;
  };

  const imageStyle = {
    display: 'block',
    ...imageProps.style,
  };

  const imageStyleV2 = {
    display: 'block',
    width: '100%',
    height: 'auto',
    aspectRatio: '16 / 9',
    ...imageProps.style,
  };

  if (isPrivate) {
    imageStyle.filter = 'blur(10px)';
    imageStyleV2.filter = 'blur(10px)';
    imageStyle.opacity = 0.9;
    imageStyleV2.opacity = 0.9;
  }

  const privateLockIcon = (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0.9,
      }}
    >
      <IconWithTheme>
        <LockIcon color="white" size={72} />
      </IconWithTheme>
    </div>
  );

  const renderImage = () => {
    if (isV2) {
      return (
        <RelativeContainer style={{ overflow: 'hidden' }}>
          {wrapWithLink(
            <Image
              src={imageSrc}
              alt={title}
              shimmerOnLoad
              preset={imagePreset || (stacked === 'always' ? 'card--palm-large' : 'card')}
              quality={isPrivate ? 5 : null}
              {...imageProps}
              style={imageStyleV2}
              objectFit="cover"
              objectPosition="center"
            />,
          )}
          {isPrivate && <>{privateLockIcon}</>}
          {renderAfterImage(isV2)}
        </RelativeContainer>
      );
    }
    return (
      <RelativeContainer style={{ overflow: 'hidden' }}>
        {wrapWithLink(
          <Rectangle
            radius={stacked === 'always' ? 'roundedTop' : 'rounded'}
            responsive={[
              {
                minWidth: 'tab',
                props: { radius: stacked === 'never' ? 'rounded' : 'roundedTop' },
              },
            ]}
            style={{ overflow: 'hidden', width: '100%' }}
            component="div"
          >
            <Image
              src={imageSrc}
              alt={title}
              shimmerOnLoad
              // loading="lazy" DISABLED DUE TO CHROME BUG: https://crbug.com/846170
              fullWidth
              preset={imagePreset || (stacked === 'always' ? 'card--palm-large' : 'card')}
              quality={isPrivate ? 5 : null}
              {...imageProps}
              style={imageStyle}
            />
          </Rectangle>,
        )}
        {isPrivate && (
          <Show minWidth="tab" component="div">
            {privateLockIcon}
          </Show>
        )}
        {renderAfterImage()}
      </RelativeContainer>
    );
  };

  const renderInfoLine = () => {
    if (totalImages) {
      return <InfoLine color={dateColor}>{`${totalImages} Images`}</InfoLine>;
    }
    if (duration) {
      const minutes = Math.floor((duration % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor(duration % 60)
        .toString()
        .padStart(2, '0');
      return <InfoLine color={dateColor}>{`${minutes}:${seconds}`}</InfoLine>;
    }

    return (
      <InfoLine color={dateColor}>
        <PublishedDate date={date} />
      </InfoLine>
    );
  };

  const renderBody = () => {
    if (isV2) {
      return (
        <FlexContainer flexDirection="column" justifyContent="space-between" style={{ flex: 1 }}>
          {wrapWithLink(
            <FlexContainer flexDirection="column">
              <Typography
                component="div"
                preset={stacked === 'never' ? 'subtitle--small' : 'subtitle--large'}
                weight="bold"
                size={16}
                color={titleColor}
              >
                {title}
              </Typography>
              <Show minWidth="fullHeader" component="div">
                <Typography preset="description" size={13}>
                  {tagline}
                </Typography>
              </Show>
            </FlexContainer>,
          )}
          <Cushion top="small">
            <FlexContainer alignItems="center" flexWrap="wrap" style={{ rowGap: '5px' }}>
              {category && <CategoryTag category={category} />}
              <Cushion left="xsmall" component="div">
                {renderInfoLine()}
              </Cushion>
            </FlexContainer>
          </Cushion>
        </FlexContainer>
      );
    }
    return (
      <>
        {category && (
          <Space
            bottom={stacked === 'always' ? 'small' : 'xsmall'}
            responsive={[{ minWidth: 'tab', props: { bottom: 'small' } }]}
            component="div"
          >
            <CategoryTag category={category} />
          </Space>
        )}
        {wrapWithLink(
          <Typography
            component="div"
            preset={stacked === 'never' ? 'subtitle--small' : 'subtitle--large'}
            color={titleColor}
          >
            {isPrivate && (
              <Space right="xsmall">
                <IconWithTheme>
                  <LockIcon />
                </IconWithTheme>
              </Space>
            )}
            {title}
          </Typography>,
        )}
        {date && (
          <Cushion
            top={stacked === 'never' ? 'small' : 'medium'}
            responsive={[
              { maxWidth: 'tab', props: { top: stacked === 'always' ? 'medium' : 'small' } },
            ]}
            component="div"
          >
            {renderInfoLine()}
          </Cushion>
        )}
      </>
    );
  };

  return wrapWithOnClickLink(
    <MediaItem
      style={{
        backgroundColor: isV2 ? 'transparent' : '#fff',
        borderRadius: isV2 ? 10 : 4,
        cursor: 'pointer',
        overflow: 'hidden',
        height: 'min-content',
        ...style,
      }}
      shadow={shadow}
      stacked={stacked}
      className={isV2 ? 'cardHighlightSlow' : 'cardHighlight'}
      isV2={isV2}
      renderImage={renderImage}
      renderBody={renderBody}
    />,
  );
};

Card.defaultProps = {
  category: null,
  date: null,
  linkProps: null,
  renderAfterImage: () => {},
  shadow: false,
  stacked: 'always',
  style: {},
  titleColor: 'black',
  dateColor: 'dustygrey',
  imageProps: { alignment: 'top' },
  imagePreset: null,
  isV2: false,
  tagline: null,
  totalImages: null,
  duration: null,
};

Card.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  linkProps: PropTypes.shape({
    route: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
  }),
  renderAfterImage: PropTypes.func,
  shadow: PropTypes.bool,
  stacked: PropTypes.oneOf(['always', 'tab-and-up', 'fullHeader', 'desk', 'never']),
  style: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  imageProps: PropTypes.shape(),
  imagePreset: PropTypes.string,
  titleColor: PropTypes.string,
  dateColor: PropTypes.string,
  isV2: PropTypes.bool,
  tagline: PropTypes.string,
  totalImages: PropTypes.number,
  duration: PropTypes.number,
};

export default Card;
